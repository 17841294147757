nav {
    position: fixed;
    width: 100vw;
    z-index: 1;
}

.nav-right {
    float: right;
    height: 50px;
}

nav > ul {
    padding: 0;
    margin: 0;
}

nav > ul > li {
    list-style: none;
    display: inline-block;
}

nav > ul > li > a {
    text-decoration: none;
    color: white;
    display: inline-block;
    padding: 1rem;
}

nav > ul > li > a:hover {
    color: lightsteelblue;
    transition: .5s;
    transform: scale(1.1);
}

nav > ul > li > a:active {
    border-bottom: 3px solid lightsteelblue;
}

@media only screen and (min-width: 768px) {
    nav {
        text-align: center;
        
        &.small {
            display: none;
        }
    }
}

@media only screen and (max-width: 768px) {
    nav {
        text-align: right;
        padding: .5rem;

        &.large {
            display: none;
        }

        ul.dropdown-items {
            left: 0;
            text-align: center;
            background: rgb(15, 15, 15);
            position: absolute;
            display: flex;
            flex-direction: column;
            width: 100vw;
            height: 100vh;

            li a {
                display: block;
            }
        }
    }
}