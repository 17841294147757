header,
section {
  background: rgb(15, 15, 15);
  color: white;
  width: 100%;
}

header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-height: 100vh;

  // parallax
  background-attachment: fixed;
  background-position: fixed;
  background-repeat: no-repeat;
  background-size: cover;

  .text > a {
    margin: 0 0.5rem 0 0.5rem;
    display: inline-block;
  }
}

section {
  display: grid;
  grid-template-columns: 15% auto 15%;
  grid-template-rows: auto;

  h2 {
    text-align: center;
    grid-column-start: 2;
  }

  &#About {
    grid-template-rows: 51px 75px 150px auto;

    p {
      grid-column-start: 2;
    }
  }
}

header + section,
section + section {
  margin: 5rem 0;
}

blockquote {
  margin: 0;

  p {
    max-width: 30ch;
  }
}

div.testimonials {
  display: flex;

  figure {
    flex: 1;

    blockquote {
      text-align: justify;
    }
  }
}

div.trending {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem;
  flex-basis: 2;
}

/* Misc and Media Queries */
.icon {
  width: 24px;
  height: 24px;
}

.icon:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.productimg {
  margin: 0;
  width: 100%;
}

section > div.section-content {
  grid-column-start: 2;
  grid-row-start: 3;
  color: white;

  .section-content-infoblock {
    display: flex;
    margin: 2rem 0;

    .section-content-infoblock-content {
      padding: 2rem;
      align-items: center;
      justify-content: center;
    }
  }

  .section-content-infoblock:nth-child(2n) {
    flex-direction: row-reverse;

    .section-content-infoblock-content {
      direction: ltr;
    }
  }
}

@media only screen and (min-width: 768px) {
  div.section-content > form.form {
    grid-template-columns: repeat(5, 1fr);

    .form-label {
      grid-column: 1 / span 2;
    }

    .form-input {
      grid-column: 3 / span 2;
    }

    .form-submit {
      grid-column: 3;
    }
  }
}

@media only screen and (max-width: 768px) {
  blockquote {
    p {
      max-width: none;
    }
  }

  section {
    grid-template-columns: auto;
  }

  section > div.section-content {
    &.grid {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(auto, 1fr);
    }

    .section-content-infoblock {
      flex-direction: column;
      align-items: center;

      img {
        width: 100%;
      }
    }

    .section-content-infoblock:nth-child(2n) {
      flex-direction: column;

      .section-content-infoblock-content {
        direction: unset;
      }
    }
  }

  div.trending {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  div.section-content > form.form {
    grid-template-columns: repeat(3, 1fr);

    .form-label {
      grid-column: 1;
    }

    .form-input {
      grid-column: 2 / span 2;
    }

    .form-submit {
      grid-column: 2;
    }
  }

  div.testimonials {
    flex-direction: column;
  }
}

// Contact section
div.section-content > form.form {
  padding: 1rem;
  display: grid;
  column-gap: 5px;
  row-gap: 10px;

  .form-label {
    text-align: right;
  }

  .form-input {
    background: rgba(0, 0, 0, 0);
    color: white;
    border-style: none;
    border-bottom: 1px solid silver;
  }

  textarea.form-input {
    height: 150px;
  }

  .form-submit {
    background: silver;
    border-style: none;
    border-radius: 0.25rem;
    transition: 0.5s;
  }

  .form-submit:hover {
    background: lightsteelblue;
    transition: 0.5s;
  }
}
