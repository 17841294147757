h2 {
    margin-top: 5rem;
}

.scent-card {
    position: relative;
    margin-bottom: 2rem;
    
    .scent-card-content {
        text-align: center;
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba(0, 0, 0, .7);
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1rem;
    
        .scent-card-content_title {
            font-size: xx-large;
            font-weight: 700;
        }
    
        .scent-card-content_description {
            font-size: medium;
        }
    }

    .scent-card-details {
        position: absolute;
        top: 0;
        right: 25%;
        background-color: rgba(0, 0, 0, .7);
        height: 100%;
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1rem;
    }

    .scent-card_image {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .scent-card {
        height: 50vh;

        .scent-card_image {
            object-fit: cover;
        }

        .scent-card-content {
            width: 50%;
        }

        .scent-card-details {
            position: absolute;
            top: 0;
            right: 50%;
            background-color: black;
            opacity: .7;
            height: 100%;
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 1rem;
        }
    }
}