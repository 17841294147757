main {
    min-height: 100vh;
    display: grid;
    grid-template-columns: 15% 1fr 15%;
    color: white;
    background: linear-gradient(to right bottom, rgba(0, 56, 161, 0.3), rgba(0, 56, 161, .05));
    background-size: cover;
}

a {
    text-decoration: none;
    color: white;
}

.checkout--content, .product-details--content {
    grid-column-start: 2;
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.checkout--content--header, .product-details--content--header {
    text-align: center;
}

.checkout--content--actions, .product-details--content--actions {
    display: flex;
    justify-content: center;

    button {
        margin: 0 .5rem;
    }
}

.MuiListItemText-secondary {
    color: rgb(179, 179, 179) !important;
}

@media only screen and (max-width: 768px) {
    main {
        grid-template-columns: 1fr;
    }
    
    .checkout--content, .product-details--content {
        grid-column-start: 1;
    }
}

@media only screen and (max-width: 768px) {

}