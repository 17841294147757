* {
  box-sizing: border-box;
}

html,
body {
  height: 100vh;
  background: rgb(15, 15, 15);
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(
    to right bottom,
    rgba(0, 27, 77, 0.8),
    rgba(0, 8, 24, 0.55)
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.glass {
  background: linear-gradient(
    to right bottom,
    rgba(128, 128, 128, 0.1),
    rgba(128, 128, 128, 0.025)
  );
  backdrop-filter: blur(1rem);
  border-radius: 1rem;
  box-shadow: 2px 2px 10px 1px black;

  &.square {
    border-radius: 0;
  }

  &.text {
    padding: 1rem;
  }

  &.nav,
  &.footer {
    border-radius: 0;
  }

  &.padded {
    padding: 0.5rem;
  }
}
