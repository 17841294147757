div.App {
}

.MuiCardActions-root {
  justify-content: space-around;
}

div.modal-header {
  display: flex;
  justify-content: space-between;
}

main { 
  background-size: cover;
}