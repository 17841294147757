footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    grid-column: 1 / span 3;
    padding: 1rem;
    height: 100px;
    align-self: end;
    margin-top: 1rem;

    p.createdby {
        font-size: 0.7rem;
        color: silver;
    }
}

div.social-media {
    text-align: center;

    a {
        text-decoration: none;
        color: white;
        padding: 5px;
    }

    a:hover {
        color: lightsteelblue;
        border-bottom: 1px solid lightsteelblue;
        transition: 0.5s;
    }
}